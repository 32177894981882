// Page types
import PageHome from './Pages/PageHome/PageHome'
import PageProduct from './Pages/PageProduct/PageProduct'
import PageCategory from './Pages/PageCategory/PageCategory'
import PageArticle from './Pages/PageArticle/PageArticle'
import PageContent from './Pages/PageContent/PageContent'
import PageArticleCategory from './Pages/PageArticleCategory/PageArticleCategory'
import PageProducerList from './Pages/PageProducerList/PageProducerList'
import PageCountry from './Pages/PageCountry/PageCountry'
import PageRegion from './Pages/PageRegion/PageRegion'
import PageProducer from './Pages/PageProducer/PageProducer'
import PageBlank from './Pages/PageBlank/PageBlank'
import PagePress from './Pages/PagePress/PagePress'

// Global Modules
import Header from './GlobalModules/Header/Header'
import Footer from './GlobalModules/Footer/Footer'
import CookieConsent from './GlobalModules/CookieConsent/CookieConsent'
import ProductPopup from './GlobalModules/ProductPopup/ProductPopup'
import GuidePopup from './GlobalModules/GuidePopup/GuidePopup'
import NotFoundModule from './GlobalModules/404/404'
import GlobalProduct from './GlobalModules/GlobalProduct/GlobalProduct'

// Modules
import LinkList from './Modules/LinkList/LinkList'
import Banner from './Modules/Banner/Banner'
import ProductList from './Modules/ProductList/ProductList'
import ArticleList from './Modules/ArticleList/ArticleList'
import ProducerList from './Modules/ProducerList/ProducerList'
import ClubBanner from './Modules/ClubBanner/ClubBanner'
import ProductAndArticle from './Modules/ProductAndArticle/ProductAndArticle'
import WineFinder from './Modules/WineFinder/WineFinder'
import Reviews from './Modules/Reviews/Reviews'
import QuoteAndText from './Modules/QuoteAndText/QuoteAndText'
import Recipe from './Modules/Recipe/Recipe'
import QuoteAndProduct from './Modules/QuoteAndProduct/QuoteAndProduct'
import QuoteAndImage from './Modules/QuoteAndImage/QuoteAndImage'
import ImageAndText from './Modules/ImageAndText/ImageAndText'
import TextAndImage from './Modules/TextAndImage/TextAndImage'
import TextAndProduct from './Modules/TextAndProduct/TextAndProduct'
import CatalogDownload from './Modules/CatalogDownload/CatalogDownload'
import ContactGrid from './Modules/ContactGrid/ContactGrid'
import ProductGrid from './Modules/ProductGrid/ProductGrid'
import HostedNewsroom from './Modules/HostedNewsroom/HostedNewsroom'
import QuoteAndVideo from './Modules/QuoteAndVideo/QuoteAndVideo'
import VideoAndText from './Modules/VideoAndText/VideoAndText'
import BookingForm from './Modules/BookingForm/BookingForm'

// Components
import HeroGrid from './Components/HeroGrid/HeroGrid'
import ComponentNotFound from './ComponentNotFound'
// import StoryblokSEO from './Components/StoryblokSEO/StoryblokSEO'
import Textarea from './Components/Textarea/Textarea'
import Highlight from './Components/Highlight/Highlight'
import TasteProfile from './Components/TasteProfile/TasteProfile'
import Review from './Components/Review/Review'
import ImageWithLink from './Components/ImageWithLink/ImageWithLink'
import ContactPerson from './Components/ContactPerson/ContactPerson'
import PageWineclub from './Pages/PageWineclub/PageWineclub'
import Newsletter from './GlobalModules/Footer/Newsletter/Newsletter'
import ClubInfo from './Modules/ClubInfo/ClubInfo'
import NewsletterTwoColumns from './Modules/NewsletterTwoColumns/NewsletterTwoColumns'
import NewsletterPopUp from './GlobalModules/NewsletterPopup/NewsletterPopup'
import Redirects from 'src/components/Redirects/Redirects'

// Modules
const ComponentList: any = {
  // Page types
  page_home: PageHome,
  page_product: PageProduct,
  page_category: PageCategory,
  page_article: PageArticle,
  page_article_category: PageArticleCategory,
  page_content: PageContent,
  page_producer_list: PageProducerList,
  page_country: PageCountry,
  page_region: PageRegion,
  page_producer: PageProducer,
  page_wine_club: PageWineclub,
  page_blank: PageBlank,
  page_press: PagePress,

  // Global Modules
  header: Header,
  footer: Footer,
  cookie_consent: CookieConsent,
  product_popup: ProductPopup,
  guide_popup: GuidePopup,
  404: NotFoundModule,
  product: GlobalProduct,
  newsletter_popup: NewsletterPopUp,

  // Modules
  link_list: LinkList,
  banner: Banner,
  product_list: ProductList,
  article_list: ArticleList,
  producer_list: ProducerList,
  club_banner: ClubBanner,
  product_and_article: ProductAndArticle,
  wine_finder: WineFinder,
  reviews: Reviews,
  quote_and_text: QuoteAndText,
  recipe: Recipe,
  quote_and_product: QuoteAndProduct,
  quote_and_image: QuoteAndImage,
  image_and_text: ImageAndText,
  text_and_image: TextAndImage,
  text_and_product: TextAndProduct,
  catalog_download_banner: CatalogDownload,
  contact_grid: ContactGrid,
  product_grid: ProductGrid,
  newsletter_banner: Newsletter,
  club_information: ClubInfo,
  newsletter_2_columns: NewsletterTwoColumns,
  hosted_newsroom: HostedNewsroom,
  quote_and_video: QuoteAndVideo,
  video_and_text: VideoAndText,
  booking_form: BookingForm,

  // Components
  textarea: Textarea,
  hero_grid: HeroGrid,
  highlight: Highlight,
  taste_profile: TasteProfile,
  review: Review,
  image_with_link: ImageWithLink,
  contact_person: ContactPerson,
  redirects: Redirects,
}

const Components = (type: string) => {
  if (typeof ComponentList[type] === 'undefined') {
    return ComponentNotFound
  }
  return ComponentList[type]
}

export default Components
