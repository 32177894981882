import React from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'

import SEO from 'src/components/SEO/SEO'
import { getSeo } from 'src/helpers/getSeo'
import ListHeader from 'src/components/ListHeader/ListHeader'
import { getDataSourceEntryName } from 'src/helpers/getDataSourceData'
import ProducerListCard from 'src/components/ProducerListCard/ProducerListCard'
import Components from 'src/storyblok/Components'
import { sortArrayByObjectKey } from 'src/helpers/sortArray'

import { Props } from './types'
import * as styles from './PageRegion.module.scss'

const PageRegion = ({ blok, full_slug }: Props) => {
  const {
    header_image,
    description,
    producers,
    label,
    region,
    content,
    seo_data,
  } = blok

  const sortedProducers = sortArrayByObjectKey(producers, 'name')

  const title = getDataSourceEntryName(region)
  const { seo_title, seo_description, seo_image, seo_canonical } = getSeo(
    seo_data,
    {
      title: title || '',
      description: description || '',
      image: header_image?.filename || '',
    }
  )
  return (
    <SbEditable content={blok}>
      <Flex width={1} flexDirection="column">
        <SEO
          title={seo_title}
          metaDescription={seo_description}
          image={seo_image}
          canonical={seo_canonical}
          url={full_slug}
        />
        <ListHeader
          title={title}
          label={label}
          image={header_image}
          description={description}
          slug={`/shoppen/#region=${region}`}
          linkText={`Upptäck alla viner från ${title}`}
        />
      </Flex>
      <Flex className={styles.grid} width={1} flexWrap="wrap">
        {sortedProducers?.length > 0 &&
          sortedProducers.map((item: any) => (
            <Box key={item.slug} width={[1 / 2, null, null, 1 / 3]}>
              <ProducerListCard
                name={item.name}
                slug={item.full_slug}
                background={item.content.list_background}
                image={item.content.list_image}
                logotype={item.content.logotype}
              />
            </Box>
          ))}
      </Flex>
      {content?.length > 0 && (
        <Box>
          {content.map((blok: any) => (
            <Box key={blok._uid}>
              {React.createElement(Components(blok.component), {
                key: blok._uid,
                blok: blok,
              })}
            </Box>
          ))}
        </Box>
      )}
    </SbEditable>
  )
}

export default PageRegion
