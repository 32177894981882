import React from 'react'
import SbEditable from 'storyblok-react'
import loadable from '@loadable/component'

import Components from 'src/storyblok/Components'
import SEO from 'src/components/SEO/SEO'
import { getSeo } from 'src/helpers/getSeo'

import ProductListing from 'src/components/ProductListing/ProductListing'

import { Props } from './types'
import CategoryHeader from './CategoryHeader/CategoryHeader'

const ProductsContainer = loadable(
  () => import('../../../components/ProductsContainer/ProductsContainer')
)

const PageCategory = (props: Props): JSX.Element => {
  const { blok, full_slug } = props

  const {
    title,
    preamble,
    theme,
    highlights,
    seo_data,
    topCategories,
    categories,
    content,
    bottom_content,
    productIds,
  } = blok
  const { products } = blok

  const totalProducts = productIds?.length > 0 ? productIds.length : null

  const { seo_title, seo_description, seo_image, seo_canonical } = getSeo(
    seo_data,
    {
      title: title || '',
      description: preamble || '',
    }
  )

  return (
    <SbEditable content={blok}>
      <div>
        <SEO
          url={full_slug}
          title={seo_title}
          metaDescription={seo_description}
          image={seo_image}
          canonical={seo_canonical}
        />

        <CategoryHeader
          title={title}
          preamble={preamble}
          fullSlug={full_slug}
          highlights={highlights}
          theme={theme}
          topCategories={topCategories}
          categories={categories?.length > 1 ? categories : null}
          totalProducts={totalProducts}
        />

        {(products?.length > 0 || productIds?.length > 0) && (
          <ProductsContainer
            products={products}
            productIds={productIds}
            render={(data) => <ProductListing content={content} {...data} />}
          />
        )}

        {bottom_content?.length > 0 &&
          bottom_content.map((blok: any) =>
            React.createElement(Components(blok.component), {
              key: blok._uid,
              blok: blok,
            })
          )}
      </div>
    </SbEditable>
  )
}

export default PageCategory
