import React, { useEffect, useState } from 'react'
import { Flex } from 'reflexbox'
import queryString from 'query-string'
import isNode from 'is-node'

import SEO from 'src/components/SEO/SEO'
import { getSeo } from 'src/helpers/getSeo'
import Components from 'src/storyblok/Components'
import PageNav from 'src/components/PageNav/PageNav'
import ListHeader from 'src/components/ListHeader/ListHeader'

import { Props } from './types'
// import * as styles from './PageWineclub.module.scss'

const PageWineclub = ({ blok, full_slug }: Props) => {
  const [activePage, setActivePage] = useState(0)
  const { header_image, title, preamble, content, seo_data } = blok

  const { seo_title, seo_description, seo_image, seo_canonical } = getSeo(
    seo_data,
    {
      title: title || '',
      description: preamble || '',
      image: header_image?.filename || '',
    }
  )

  useEffect(() => {
    if (!isNode) {
      const hash = queryString.parse(window.location.hash)

      if (hash && 'premium' in hash) {
        setActivePage(1)
      }
    }
  }, [])

  return (
    <Flex width={1} flexDirection="column">
      <SEO
        title={seo_title}
        metaDescription={seo_description}
        image={seo_image}
        url={full_slug}
        canonical={seo_canonical}
      />
      <ListHeader title={title} description={preamble} image={header_image} />
      <PageNav
        items={
          content &&
          content.length > 0 &&
          content.map((page: any, index: number) => ({
            name: page.title,
            active: index === activePage,
            onClick: () => {
              setActivePage(index)
            },
          }))
        }
      />
      {content?.length > 0 &&
        content[activePage] &&
        content[activePage].content &&
        content[activePage].content.length > 0 &&
        content[activePage].content.map((blok: any) =>
          React.createElement(Components(blok.component), {
            key: blok._uid,
            blok: blok,
          })
        )}
    </Flex>
  )
}

export default PageWineclub
